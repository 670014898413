.body .Chart .ChartContent {
  display: flex;
  flex-direction: column;
  color: black;
  background-color: white;

  padding-top: 32px;

  h2 {
    color: black !important;
    font-weight: 500;
    font-size: 24px;
    margin: 0 0 0 0;
  }
  h3 {
    color: black !important;
    font-weight: 500;
    font-size: 20px;
    margin: 16px;
  }

  hr {
    border-top: 1px solid gray;
    width: 90%;
    margin-top: 3%;
    margin-bottom: 3%;
  }

  div {
    font-size: 18px;
  }

  .Chart-title {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .Chart-basic-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 32px;
  }

  .Chart-address {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 32px;
  }

  .Chart-client-profile {
    margin: 0 3%;

    .Chart-profile-columns {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0 16px;
    }
  }

  .Chart-presenting-problems {
    margin: 0 5%;
  }

  .Chart-languages {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .Chart-key-info {
    margin: 0 5%;

    ul {
      padding: 0 5%;
      margin: 0;
    }
  }

  .Chart-footer {
    margin: 0 5% 2% 5%;
  }
}
