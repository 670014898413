.main .content {
  .resource-name {
    font-size: 24px;
    font-style: italic;
  }

  .crisis-links {
    color: gold;

    &:active {
      color: cornflowerblue;
    }
  }
}

.resource-item {
  margin-bottom: 8px;
}
