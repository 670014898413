.container .panel .research {
  margin-right: 32px;
}

.container .panel .debrief-item {
  margin: 8px 0;
}

.literature-list {
  margin-top: 8px;
}
