/********** MENU **********/

.nav-header {
  .controls {
    padding: 0;
    margin: 0;

    li {
      display: block;
      line-height: 120%;
      text-decoration: none;
      cursor: pointer;
      // background-color: cornflowerblue;

      a {
        display: block;
        padding: 20px 24px;
        color: rgb(255, 255, 255);
        font-size: 24px;
      }

      &:hover,
      &:focus-within {
        background-color: #446fa0;
        transition: all 0.5s ease;
      }
    }
  }
}

.Menu .panel .main {
  font-size: 20px;

  .divider {
    width: 5px;
    height: 16px;
  }

  .controls li {
    line-height: 120%;
    border-color: #204a85;
    border-style: solid;
    color: black;

    &:hover,
    &:focus-within {
      color: rgb(255, 255, 255);
      background-color: #446fa0;
      transition: all 0.5s ease;
    }
  }

  .game-buttons {
    padding: 8px;
    box-sizing: border-box;
    border-radius: 8px;

    button {
      font-size: 24px;
      padding-left: 12px;
      padding-right: 12px;
      padding: 12px;
      width: 100%;
      background-color: rgb(100, 149, 237);

      border-color: rgb(100, 149, 237);
      border-width: 4px;
      border-style: solid;

      box-sizing: border-box;
      cursor: pointer;
      border-radius: 12%/50%;

      // &.disabled,
      // &.disabled:hover {
      // 	color: rgb(46, 57, 71);
      // 	cursor: default;
      // 	text-shadow: none;
      // 	box-shadow: none;
      // 	background-color: gray;
      // }

      &:hover {
        color: rgb(18, 39, 77);
        background-color: rgb(205, 220, 243);
      }
    }
  }
}

.Menu {
  &__link {
    display: block;
    border: none;
    font-family: arial, sans-serif;
    color: inherit;
    text-decoration: none;
    cursor: pointer;
    padding: 16px;
    width: 100%;
    box-sizing: border-box;
    &--active {
      color: white;
      background-color: #446fa0;
    }
  }
}
