/********** VIDEO **********/
@import url(//css/video-js.css);

iframe,
#vid-player {
  width: 100%;
  height: 90vh;
  aspect-ratio: 16/9;
  max-height: 90vh;
  overflow: hidden;
  margin-top: 0;
  box-sizing: border-box;
}

.video,
.videoplayer {
  // position: absolute;
  // top: 0;
  // left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.left.controls,
.right.controls {
  position: absolute;
  top: 10px;
  right: 20px;
  padding: 10px;
  z-index: 13;
}
.left.controls {
  left: 20px;
  right: auto;
}
