.view .container .panel {
  header {
    margin-bottom: 16px;
  }

  .resource-button {
    margin: 16px auto;

    border-radius: 8px;
    padding: 16px;
    font-size: 24px;
    background-color: #446fa0;

    &:hover {
      background-color: #223c60;
      transition: all 0.3s ease;
      color: var(--primary-text-highlight);
      // text-shadow: 0 0 10px white;
      box-shadow: 0 0 10px white;
    }
  }
}
