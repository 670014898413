/********** DECISION POINT **********/

.progress {
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;

  progress {
    border-radius: 7px;
    width: 50%;
    height: 22px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  }
  progress::-webkit-progress-bar {
    background-color: white;
    border-radius: 7px;
  }
  progress::-webkit-progress-value {
    background-color: rgb(12, 12, 66);
    border-radius: 7px;
    box-shadow: cornflowerblue;
  }
}
.progress .bar {
  position: relative;
  width: 14px;
  height: 14px;
  min-width: 14px;
  background-color: #fff;
  border-radius: 8px;
}
.question {
  // // position: absolute;
  // top: 5%;
  // left: 0;
  // right: 0;
  // height: 50%;
  // margin: 0 5%;
  font-size: 1.4em;
  text-align: center;
  color: var(--primary-text-highlight);
  padding: 72px;
}
.question .vertical_outer {
  vertical-align: bottom;
}
.question .vertical_inner {
  vertical-align: middle;
}
.option_box {
  // position: absolute;
  // left: 0;
  // right: 0;
  // bottom: 5%;
  min-height: 30%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
}
.option {
  display: block;
  width: 30%;
  max-width: 500px;
  height: 160px;
  padding: 0.4em;
  box-sizing: border-box;
  font-size: 1em;
  overflow-wrap: break-word;
}
.option button {
  display: block;
  font: inherit;
  text-align: inherit;
  border: none;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 20px;
  border-radius: 13px;
  background-color: #000;
  background-color: var(--option-background);
  color: var(--primary-text-highlight);
  cursor: pointer;
  transition: all 0.1s linear;
}
.option button:hover {
  color: black;
  background-color: var(--primary-text-color);
  box-shadow: 0 0 10px var(--option-background),
    0 0 20px var(--option-background);
  transform: scale3d(1.05, 1.05, 1.05);
}
