@charset 'utf-8';
@use "css/icomoon.css";

@import url(//fonts.googleapis.com/css?family=Roboto+Condensed:400,700);

$max-mobile-width: 800px;

button,
.Button {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  text-align: center;
  background: none;
  border: none;
  font-family: arial, sans-serif;
  color: rgb(255, 255, 255);
  text-decoration: none;
  cursor: pointer;

  font-size: 20px;
  padding: 8px;
  background-color: cornflowerblue;
}

.fullscreen {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);

  .nav-header {
    display: flex;
    flex-direction: row;
    position: sticky;
    top: 0;

    color: white;
    background-color: rgb(16, 54, 95);
    // border-bottom: black 2px solid;
    // box-shadow: 0px 5px 5px black;
    z-index: 1000;
    padding: 0 16px;

    @media only screen and (max-width: $max-mobile-width) {
      flex-direction: column;
      h1 {
        order: -1;
      }
      > * {
        text-align: center;
        > * {
          margin: 0 auto;
        }
      }
      .nav-header__content-dropdown {
        display: block;
        ul {
          list-style: none;
          padding: 0;
          margin: 0;
          width: 100%;
        }

        li {
          line-height: 120%;
          border-color: #223c60;
          border-style: solid;
          color: black;

          &:hover,
          &:focus-within {
            color: rgb(255, 255, 255);
            background-color: #446fa0;
            transition: all 0.5s ease;
          }
        }

        .Menu__link {
          display: block;
          border: none;
          font-family: arial, sans-serif;
          color: inherit;
          text-decoration: none;
          text-decoration-line: none;
          text-decoration-thickness: initial;
          text-decoration-style: initial;
          text-decoration-color: initial;
          cursor: pointer;
          padding: 16px;
          width: 100%;
          box-sizing: border-box;
        }
      }
    }

    h1 {
      margin: auto;
    }

    button,
    .Button {
      font-size: 24px;
      background-color: transparent;
      padding: 24px;

      &:hover,
      &:focus-within {
        background-color: #446fa0;
        transition: all 0.5s ease;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: left;

    .banner {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      background-color: #d8e3f7;

      // background-image: url(".../images/background.jpg");

      @media only screen and (max-width: $max-mobile-width) {
        display: none;
      }

      .img-left {
        width: 440px; /* width of container */
        height: 440px; /* height of container */
        object-fit: cover;
        object-position: 0 80%; /* try 20px 10px */
        margin-left: 32px;
      }

      .img-right {
        width: 440px; /* width of container */
        height: 440px; /* height of container */
        object-fit: cover;
        object-position: 0 -40px; /* try 20px 10px */
      }

      .quote {
        color: rgba(16, 33, 51, 0.8);
        font-size: 32px;
        font-weight: 400;
        margin: auto;
        max-width: 400px;

        @media only screen and (max-width: 900px) {
          display: none;
        }

        p {
          padding: 0;
          margin: 0;
        }

        span {
          font-size: 28px;
          font-style: italic;
          font-weight: 500;
        }
      }

      // img {
      //   height: 50vh;
      // }

      &--collapsed {
        max-width: 0;
        padding-left: 0;
        padding-right: 0;
        display: none;
      }
    }

    .body {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      background-color: #204a85;

      @media only screen and (max-width: $max-mobile-width) {
        flex-direction: column;
        > div {
          width: 100%;
        }

        div.nav-content {
          display: none;
        }

        div.view {
          order: 2;
        }
      }

      .container {
        font-size: 20px;
        margin-top: 64px;
      }

      .nav-content {
        order: 2;
        overflow: hidden;

        .nav-menu-title {
          font-size: 28px;
          color: white;
          margin-left: 4px;
        }

        &--collapsed {
          max-width: 0;
          padding-left: 0;
          padding-right: 0;
        }
      }

      .nav-menu {
        box-sizing: border-box;
        max-width: 100%;

        font-weight: 300;
        font-size: 24px;
        padding: 0;

        background-color: rgb(197, 202, 233);
        padding-top: 0;
        margin-right: 10vw;
        overflow: hidden;

        &__content-dropdown {
          display: none;
        }

        .container {
          width: max-content;
          overflow: visible;
          margin: auto;
        }

        ul {
          list-style: none;
          padding: 0;
          margin: 0;

          .controls {
            margin: 4px;
            background-color: white;
            border-color: black;
            border-radius: 4px;
            border-width: 2px;
            border-style: solid;
          }
        }
      }

      .view {
        display: block;
        flex-basis: 0;
        flex-grow: 1;
        width: 0;

        order: 1;

        .feedback {
          margin: 4vw;
        }

        h2 {
          color: white;
          margin: 0;
        }

        @media only screen and (max-width: $max-mobile-width) {
          width: 100%;
          .panel {
            padding: 16px;
            margin: 0;
          }
        }

        .panel {
          display: flex;
          flex-flow: column nowrap;
          align-items: center;

          color: white;

          // width: 450px;
          max-width: 90%;
          max-height: 95%;
          padding: 0px 40px;
          box-sizing: border-box;
          background-color: rgba(16, 33, 51, 0.8);
          border-radius: 16px;
          padding: 2vw;
          margin: 16px auto;
        }

        .feedback {
          display: flex;
          flex-flow: column nowrap;
          align-items: center;

          color: white;

          // width: 450px;
          max-width: 90%;
          max-height: 95%;
          padding: 0px 40px;
          box-sizing: border-box;
          background-color: rgba(16, 33, 51, 0.8);
          border-radius: 16px;
          padding: 2vw;
          margin: 16vh auto 16px auto;
        }

        .container {
          // padding: 16px;
          // background-color: rgb(100, 100, 100);
          // border-radius: 16px;
          // border-color: black;
          // border-style: solid;
          // border-width: 2px;

          .content ul {
            padding-left: 20px;
            margin-top: 0;
            margin-bottom: 8px;
          }
        }

        ul {
          padding: 0;
        }
      }
    }
  }
}
