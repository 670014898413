.content .body .view .Chart {
  display: flex;
  flex-direction: column;
  align-content: center;

  .next-button {
    width: 10vw;
    margin: 16px auto;

    border-radius: 8px;
    padding: 16px;
    font-size: 24px;
    background-color: #446fa0;

    &:hover {
      background-color: cornflowerblue;
      transition: all 0.3s ease;
      color: var(--primary-text-highlight);
      text-shadow: 0 0 10px white;
      box-shadow: 0 0 10px black;
    }
  }
}

.ChartModal {
  z-index: 100;
  .chart {
    display: block;
    max-width: 90vw;
    max-height: 90vh;
    margin: auto;
    z-index: 100;
    // cursor: pointer;
  }

  button {
    margin: 12px auto;
  }
}

.polaroid {
  float: right;
  position: absolute;
  right: 400px;
  flex-shrink: 1;
}

.body .view .Chart {
  height: 100%;
  box-sizing: border-box;
  margin: 0;

  &__panel {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    box-sizing: border-box;
    gap: 1rem;
    padding-bottom: 16px;
    margin-bottom: 16px;

    .Chart-folder {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      padding: 0;
      box-sizing: border-box;
      max-width: 100%;
      object-fit: contain;
      margin: auto;
      // cursor: pointer;
      // background-image: url("chart-folder.png");

      .Chart-folder-left {
        width: 0;
      }
    }
  }

  &__proceed-button {
    align-self: flex-end;
  }
}
