@font-face {
  font-family: 'icomoon';
  src:  '../fnt/icomoon.eot?xklcuw';
  src:  url('../fnt/icomoon.eot?xklcuw#iefix') format('embedded-opentype'),
    url('../fnt/icomoon.ttf?xklcuw') format('truetype'),
    url('../fnt/icomoon.woff?xklcuw') format('woff'),
    url('../fnt/icomoon.svg?xklcuw#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-left:before {
  content: "\e900";
}
.icon-arrow-right:before {
  content: "\e901";
}
.icon-menu:before {
  content: "\e902";
}
.icon-x:before {
  content: "\e903";
}