.DropDown {
  position: relative;
  display: inline-block;

  &__button {
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
  }

  & &__content {
    display: none;
    position: absolute;
    right: 0;
    background-color: #223c60;
    min-width: max-content;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;

    a {
      color: black;
      padding: 12px 16px;
      text-decoration: none;
      display: block;
    }

    button {
      width: 100%;

      &:hover {
        background-color: cornflowerblue;
      }
    }
  }
  &:focus-within &__content,
  &--expanded &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
  }

  &:hover &__button {
    background-color: cornflowerblue;
  }
}
