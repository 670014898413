// .option_box {
//   // position: absolute;
//   // left: 0;
//   // right: 0;
//   // bottom: 5%;
//   min-height: 30%;
//   display: flex;
//   flex-direction: row;
//   justify-content: center;
//   text-align: center;
// }

.intro {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;

  color: white;

  // width: 450px;
  max-width: 95%;
  max-height: 95%;
  padding: 0px 40px;
  box-sizing: border-box;
  background-color: rgba(16, 33, 51, 0.8);
  border-radius: 16px;
  padding: 2vw;
  margin: 10vw;

  footer {
    width: 100%;
    margin-top: 16px;

    button {
      margin: auto;
      border-radius: 8px;
      padding: 16px;
      font-size: 24px;
      background-color: #446fa0;

      &:hover {
        background-color: cornflowerblue;
        transition: all 0.3s ease;
        color: var(--primary-text-highlight);
        text-shadow: 0 0 10px white;
        box-shadow: 0 0 10px black;
      }
      &:focus-within {
        background-color: cornflowerblue;
      }
    }
  }
}
