// /********** GAME SUMMARY **********/

// .main.summary {
//   height: 600px;
//   overflow-y: auto;
// }
// .summary .score {
//   color: #fff;
// }
// .summary a {
//   color: #fff;
// }
// .summary li {
//   margin: 0 auto;
//   margin-left: 60px;
//   padding: 10px 0;
// }
// .summary .responses {
//   /*display: none;*/
//   overflow-y: auto;
//   min-height: 300px;
//   margin-top: 0;
//   padding: 10px;
//   background-color: #fff;
// }
// .summary .responses.expanded {
//   display: block;
// }
// .summary h2 {
//   margin-top: 40px;
// }
// .summary .correct {
//   color: #393;
// }
// .summary .wrong {
//   color: red;
// }
// .summary a {
//   text-decoration: underline;
// }
// .links {
//   margin: 40px 0;
// }

.button {
  color: white;
  font-size: 20px;
  padding: 16px;
  background-color: rgb(34, 70, 109);
  margin: 8px;
  border-radius: 8px;
  display: inline-block;

  &:hover,
  &:focus {
    background-color: cornflowerblue;
  }
}
