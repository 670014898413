/********** CREDITS **********/

.credits {
  width: 100%;
  text-transform: none;

  .content {
    h2 {
      margin: 12px 0 0 0;
      padding: 0;
    }
    h3 {
      margin: 12px 0 0 0;
      padding: 0;
      font-size: 26px;
      font-weight: 500;
    }
    h4 {
      margin: 12px 0 0 0;
      padding: 0;
      font-size: 20px;
      font-style: italic;
      font-weight: 500;
    }

    ul {
      margin: 0;
    }

    font-weight: 400;
  }
}

.credits-content {
  li {
    margin: 2px 0;
  }

  p {
    margin: 2px 0;
  }
  a {
    color: #ffeb5a;
  }

  .person {
    font-weight: 500;
    font-size: 24px;
    padding-top: 16px;
  }
  .title {
    font-weight: 400;
    font-size: 20px;
    padding: 0;
  }

  .organization {
    font-weight: 200;
    font-size: 20px;
    font-style: italic;
    padding: 0;
  }
}
