body {
  // margin: 30px;
  background-color: #e9e9e9;
}

div.polaroid {
  width: 280px;
  padding: 20px 20px 10px 20px;
  color: black;
  border: 1px solid #bfbfbf;
  background-color: white;
  box-shadow: 10px 10px 5px #aaaaaa;

  .caption {
    font-size: 24px;
    font-family: sans-serif;
  }
}

div.rotate_right {
  float: left;
  -ms-transform: rotate(7deg); /* IE 9 */
  -webkit-transform: rotate(7deg); /* Safari */
  transform: rotate(7deg);
}

div.rotate_left {
  float: left;
  -ms-transform: rotate(-8deg); /* IE 9 */
  -webkit-transform: rotate(-8deg); /* Safari */
  transform: rotate(-8deg);
}
